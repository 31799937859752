import generatePicker from 'antd/es/date-picker/generatePicker'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import moment from 'moment'

const extendedMomentGenerateConfig = {
	...momentGenerateConfig,
	getMillisecond: (date: moment.Moment) => date.milliseconds(),
	setMillisecond: (date: moment.Moment, milliseconds: number) => date.milliseconds(milliseconds),
}

const DatePickerConfig = generatePicker<moment.Moment>(extendedMomentGenerateConfig)

export default DatePickerConfig
